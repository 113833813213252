import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        <header>
          <div className="page_width rel">
            <nav>
              <a href="/" id="logo" />
              <span id="menu_btn" />
              <ul id="menu">
                <li>
                  <a href="/">Hlavná stránka</a>
                </li>
                <li>
                  <a href="/lieky/">Lieky</a>
                </li>
                <li>
                  <a href="/radca/">Radca</a>
                </li>
                <li>
                  <a href="/upravte_liek/">Test</a>
                </li>
                <li>
                  <a href="/kontakt/">Kontakt</a>
                </li>
                <li>
                  <a href="/otazky_a_odpovede/">Otázky a odpovede</a>
                </li>
                <li>
                  <a href="/kde_kupit/">KDE KÚPIŤ </a>
                </li>
              </ul>
            </nav>
          </div>
        </header>
        <div id="page" className="page_width">
          <div id="breadcrumbs">
            <a href="/">Hlavná stránka</a>
            <a href="/lieky/olynth_05/" className="sel">
              {" "}
              &gt; OLYNTH® 0,05%
            </a>
          </div>
          <div id="page_content">
            <div className="col2">
              <div className="product_content">
                <div className="product_name">
                  <div className="info">
                    <p className="name">
                      OLYNTH<sup>®</sup> 0,05% nosová roztoková aerodisperzia
                    </p>
                    <div className="desc">
                    RÝCHLA A ÚČINNÁ LIEČBA PRÍZNAKOV NÁDCHY U DETÍ OD 2 DO 6
                      ROKOV
                    </div>
                  </div>
                  <div className="photo">
                    <span className="stamp ">
                      <img src="/upload/znaczek_AZ3.png" alt="AZ3" id="az3" />
                      <img src="/upload/10h_znaczek.png" alt="10h" id="h10" />
                    </span>
                    <img src="/web/kcfinder/uploads/files/produkty/sk/6.png" />
                  </div>
                </div>
                <div className="wysiwyg">
                  <div
                    style={{
                      "-webkit-text-align": "justify",
                      "text-align": "justify"
                    }}
                  >
                    <div>
                      <strong>
                      Používanie nosových sprejou u detí je dôležité, pretože
                        uvoľňujú upchatý nos a vedľajšie nosové dutiny, čím u
                        dieťaťa uvoľňujú dýchanie nosom počas dňa i noci.
                        Uvoľnením vedľajších nosových dutín sa môže predísť
                        výskytu možných komplikácií, ako je ťažký zápal
                        stredného ucha.
                      </strong>
                    </div>
                    <div>&nbsp;</div>
                    <div>
                      OLYNTH<sup>®</sup> 0,05% je liek vo forme nosovej
                      roztokovej aerodisperzie určený:
                    </div>
                    <ul>
                      <li>
                        na zmiernenie prekrvenia a opuchu nosovej sliznice pri
                        akútnej, vazomotorickej a alergickej nádche
                      </li>
                      <li>
                      na urýchlenie uvoľnenia sekrécie pri zápale prinosových
                        dutín (paranazálna sinusitída) a pri katare Eustachovej
                        (sluchovej) trubice spojenom s nádchou
                      </li>
                    </ul>
                    <div>&nbsp;</div>
                    <div>
                      <strong>Hlavné výhody</strong>
                    </div>
                    <ul>
                      <li>
                        rýchlo uvoľňuje upchatý nos a vedľajšie prinosových dutiny
                      </li>
                      <li>
                        vytvára jemný rozprašok, a preto je aplikácia šetrná aj
                        pre malé deti
                      </li>
                    </ul>
                    <div>&nbsp;</div>
                    <div>
                      <strong>Dávkovanie a spôsob podávania</strong>
                    </div>
                    <div>Liek je určený na liečbu detí od 2 do 6 rokov.</div>
                    <div>
                      Aplikuje sa podľa potreby, najviac však 3x denne 1 dávka
                      do každej nosovej dierky.
                    </div>
                    <div>&nbsp;</div>
                    <div>
                      <strong>Upozornenie</strong>
                    </div>
                    <div>
                      Liek OLYNTH<sup>®</sup> 0,05% sa nemá používať dlhšie ako
                      7 dní, pokiaľ nie je stanovené lekárom inak. Aplikáciu je
                      možné opakovať len po tom, čo liečba bola na niekoľko dní
                      prerušená.
                    </div>
                    <div>&nbsp;</div>
                    <div>
                      Z hygienických dôvodov sa OLYNTH<sup>®</sup> 0,05% nemá po
                      otvorení používať dlhšie ako 24 týždňov.
                    </div>
                    <div>&nbsp;</div>
                  </div>
                  <p>
                    <a
                      className="btn_link"
                      href="http://www.sukl.sk/buxus/generate_page.php?page_id=386&lie_id=62521"
                      target="_blank"
                    >
                      PIL - Písomná informácia pre používateľa (link na
                      oficiálne webové stránky ŠÚKL)
                    </a>
                  </p>
                </div>
              </div>
            </div>
            <div className="col1">
              <div className="related">
                <p className="name">Zistiť viac</p>
                <div className="line">
                  <a href="/radca/deti/upchaty_nos_u_detC3AD/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage:"url(/web/kcfinder/uploads/files/pokonaj_zatkany_nos-_dziecka_szybko_skutecznie.png)"}} />
                    </div>
                    <div className="c2">Upchatý nos u detí </div>
                  </a>
                </div>
                <div className="line">
                  <a href="/radca/deti/imunita_dietata_dbajte_a_posilnujte/">
                    <div className="c1">
                      <span className="photo" style={{ backgroundImage: "url(/web/kcfinder/uploads/files/iStock_63844397_LARGE.png)"}} />
                    </div>
                    <div className="c2">
                      Imunita dieťaťa – dbajte a posilňujte{" "}
                    </div>
                  </a>
                </div>
              </div>
              <a href="/upravte_liek/" className="link_box link">
                <img src="/web/kcfinder/uploads/files/box/sk/Test_SK.png" />
              </a>
              <a href="/lieky/olynth_05/" className="quiz_btn" />
            </div>
            <div className="clear" />
          </div>{" "}
        </div>
        <Footer></Footer>{" "}
        
      </div>
    );
  }
}

export default Page;
